<template>
  <div class="mt-28">
    <label for="comment" class="block text-xl font-medium text-gray-900">{{loc.my_subscriptions}}</label>
    <p class="text-sm mt-5">{{ loc.subscriptions_desc }}</p>
    <ul  role="list" class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 py-6" v-if="subscriptionsData && (benefit_preview_type === 'grid')">
      <li
          v-for="sub in subscriptionsData"
          :key="sub.benefit.id"
          class="h-72 col-span-1 bg-white rounded-lg shadow-xl cursor-pointer hover:text-base-500 text-gray-700 md:transform hover:scale-105 transition duration-200 ease-in-out"
          @click="$router.push(`/benefit/${sub.benefit.id}`)"
      >
        <!--      <benefit-box :data="benefit" :key="benefit.id" />-->

        <div class="h-full w-full rounded-xl">

          <div v-if="sub.benefit.fixed" :class="`w-full h-1/6 flex items-center justify-center transform translate-y-3`">
            <div :class="`flex items-center space-x-2 p-2  ${sub.benefit.fixed ? 'w-full justify-center' : 'w-10/12'}`">
              <p :class="`text-sm font-medium text-ellipsis ${sub.benefit.fixed? 'text-left': 'text-left'}`">{{sub.benefit.name}}</p>
            </div>

            <!--            <div v-if="benefit.discount" class="w-2/12 bg-base-600 rounded-bl-lg text-white text-center font-semibold p-2 rounded-bl-xl">-{{benefit.discount}}%</div>-->
          </div>

          <div :class="`rounded-tr-xl rounded-tl-xl w-full ${sub.benefit.fixed ? 'h-5/6' : 'h-3/6'} bg-gray-400 bg-cover`" :style="`background-image: url('${getBaseImg(sub.benefit.pictures)}'); background-position: center;`">
            <div v-if="sub.benefit.discount" class="w-2/12 bg-base-600 text-white text-center font-semibold p-2 rounded-bl-xl rounded-tr-xl ml-auto">-{{sub.benefit.discount}}%</div>
          </div>


          <div style="background-color: rgb(233,238,251);  height: 15%;" class="px-1 w-full flex justify-between items-center" v-if="benefits_type !== 'fixed'">
            <div class="w-7/12 flex items-center justify-start">
              <div v-if="sub.benefit.price || sub.benefit.discount" class="space-y-0">
                <p class="font-bold ml-1 leading-4" v-if="!sub.benefit.nfc && !sub.benefit.fixed && sub.benefit.price > 0">{{sub.benefit.price_discount_currency}}</p>
                <p class="font-medium text-gray-400 line-through text-sm md:text-base ml-1 mr-2 leading-3" v-if="!sub.benefit.fixed && sub.benefit.price > 0 && sub.benefit.discount">{{sub.benefit.price_currency}}</p>
                <div v-else></div>
              </div>
              <ul role="list" class="space-y-6" v-if="benefits && benefit_preview_type === 'list' && !benefits[0].fixed">
                <li
                    v-for="sub in subscriptionsData"
                    class="h-24 col-span-1 bg-white rounded-lg cursor-pointer text-gray-700 shadow-xl md:transform hover:scale-105 transition duration-200 ease-in-out"
                    @click="$router.push(`/benefit/${sub.benefit.id}`)"
                >
                  <div class="w-full h-full flex justify-between rounded-lg space-x-4">
                    <div class="rounded-l-lg bg-cover w-40 h-full shadow-2xl" :style="`background-image: url('${getBaseImg(sub.benefit.pictures)}'); background-size: contain; background-position: center center; background-repeat: no-repeat;`"/>
                    <p class="w-64 text-md font-bold my-auto">{{sub.benefit.name}}</p>
                    <p class="w-64 text-xs my-2 font-medium cut-text text-gray-500 w-3/12 text-justify"> {{extractContent(sub.benefit.short_description)}} </p>

                    <div class="ml-auto flex justify-end items-center w-4/12">
                      <div class="text-base-500 mr-4">
                        <p class="font-normal line-through" v-if="!sub.benefit.fixed && sub.benefit.price > 0 && sub.benefit.discount">{{monetary(sub.benefit.price, sub.benefit.currency)}}</p>
                        <p class="font-semibold" v-if="!sub.benefit.fixed && sub.benefit.price > 0">{{monetary(sub.benefit.price * (100 - sub.benefit.discount) / 100, sub.benefit.currency)}}</p>
                      </div>
                      <div class="flex w-1/3 pl-4 space-x-2">
                        <shopping-cart-icon v-if="!sub.benefit.fixed && !sub.benefit.special_offer && sub.benefit.price > 0" @click.stop="toggleCart(sub.benefit.id)" :class="`hover:text-base-500 ${userCartIds.includes(sub.benefit.id) ? 'text-base-500': ''} w-6 h-6 text-gray-500 md:transform hover:scale-125 transition duration-200 ease-in-out`" />
                        <heart-icon v-if="benefits_type!='fixed'" @click.stop="toggleFavorites(sub.benefit.id)" :class="`hover:text-red-600 ${userFavoriteIds.includes(sub.benefit.id) ? 'text-red-600': ''} w-6 h-6 text-gray-500 md:transform hover:scale-125 transition duration-200 ease-in-out`" />
                      </div>
                      <div v-if="benefit.discount" class="mb-auto bg-base-600 rounded-tr-xl rounded-bl-xl p-2 font-semibold text-white w-1/6">
                        -{{benefit.discount}}%
                      </div>
                      <!--          psudo element-->
                      <div v-else class="mb-auto bg-white rounded-tr-xl rounded-bl-xl p-2 font-semibold text-white w-1/6">
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="flex justify-center items-center px-1" v-if="(!sub.benefit.nfc && !Number(sub.benefit.price)) || (sub.benefit.nfc)">
                <font-awesome-icon v-if="!sub.benefit.nfc && !Number(sub.benefit.price)" icon="fa-solid fa-file-invoice-dollar" />
                <font-awesome-icon v-if="benefit.nfc" icon="fa-solid fa-mobile-screen-button" />
                <p v-if="sub.benefit.nfc" class="ml-2 font-medium">IN-STORE</p>
                <p v-if="!sub.benefit.nfc && !Number(sub.benefit.price)" class="ml-2 font-medium">INVOICE</p>
              </div>
            </div>
            <div class="w-5/12 h-full flex justify-end items-center gap-x-1.5 text-gray-600">
              <!-- TODO: Ishendlovati prop subskripcija kako treba kad bekend bude gotov kao i ovaj includes umesto cart da bude subs -->
              <div v-if="sub.benefit.subscription" :class="`h-6 w-6 group`">
                <font-awesome-icon
                    v-if="!sub.benefit.fixed && !sub.benefit.special_offer && sub.benefit.price > 0"
                    icon="fa-solid fa-calendar"
                    @click.stop="openConfirmModal({ benefit: sub.benefit, user_id: user.id })"
                    :class="`group-hover:hidden h-6 w-6 transform hover:scale-110 ${sub.subscription_active ? 'text-green-600 hover:text-red-600': 'text-gray-600 hover:text-green-600'} transition duration-200 ease-in-out`"
                />
                <font-awesome-icon
                    v-if="!sub.benefit.fixed && !sub.benefit.special_offer && sub.benefit.price > 0"
                    :icon="`fa-solid ${subscriptionsData.some(sub => sub.benefit.id === sub.benefit.id) ? 'fa-calendar-xmark': 'fa-calendar-check'} `"
                    @click.stop="openConfirmModal({ benefit: sub.benefit, user_id: user.id })"
                    :class="`hidden group-hover:block h-6 w-6 transform hover:scale-110 ${sub.subscription_active ? 'text-green-600 hover:text-red-600': 'text-gray-600 hover:text-green-600'} transition duration-200 ease-in-out`"
                />
              </div>
<!--              toggleSubscription({ productId: benefit.id, userId: user.id })-->

              <div v-if="!sub.benefit.subscription" :class="`h-6 w-6 ${userCartIds.includes(sub.benefit.id) ? '': 'group'}`">
                <font-awesome-icon
                    v-if="!sub.benefit.fixed && !sub.benefit.special_offer && sub.benefit.price > 0"
                    icon="fa-solid fa-shopping-cart"
                    @click.stop="toggleCart(benefit.id)"
                    :class="`group-hover:hidden h-6 w-6 transform hover:scale-110  xl:hover:text-base-500 ${userCartIds.includes(benefit.id) ? 'text-base-500': ''} transition duration-200 ease-in-out`"
                />
                <font-awesome-icon
                    v-if="!sub.benefit.fixed && !benefit.special_offer && sub.benefit.price > 0"
                    icon="fa-solid fa-cart-plus"
                    @click.stop="toggleCart(benefit.id)"
                    :class="`hidden group-hover:block h-6 w-6 transform hover:scale-110  xl:hover:text-base-500 ${userCartIds.includes(sub.benefit.id) ? 'text-base-500': ''} transition duration-200 ease-in-out`"
                />
              </div>
              <heart-icon v-if="benefits_type !== 'fixed'" @click.stop="toggleFavorites(sub.benefit.id)" :class="`h-7 w-7 transform hover:scale-110 mr-1 xl:hover:text-red-600 ${userFavoriteIds.includes(sub.benefit.id) ? 'text-red-600': ''} transition duration-200 ease-in-out`" />
            </div>
          </div>

          <div v-if="!sub.benefit.fixed" :class="`w-full h-2/6 pb-2`">
            <div :class="`h-1/2 p-2 pb-1  ${sub.benefit.fixed ? 'w-full justify-center' : 'w-11/12'}`">
              <p :class="`text-sm leading-4 font-black line-clamp-2 ${sub.benefit.fixed? 'text-left': 'text-left'}`">{{sub.benefit.name}}</p>
            </div>
            <div>
              <p :class="`h-1/2 text-sm leading-4 px-2 text-gray-400 pt-3 line-clamp-2 overflow-hidden mr-1`" v-html="sub.benefit.short_description"></p>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <a href="list" name="list" id="list"></a>
    <div v-if="subscriptionsData.length === 0" class="py-40 font-medium text-sm flex items-center justify-center">
      <div class="space-y-4 text-center">
        <font-awesome-icon  icon="fa-calendar" class="text-4xl" />
        <p>{{ loading ? loc.loading : loc.no_subscriptions }}</p>
      </div>
    </div>
  </div>
  <NewModal :is-open="unsubscribeModal" size="small" @onClose="unsubscribeModal = false">
    <ConfirmModal
        :title="loc.unsubscribe"
        :confirm-btn-text="loc.unsubscribe"
        icon-color="text-red-600"
        confirm-button-classes="bg-red-600 hover:bg-red-200 text-white hover:text-red-600 border-2 border-red-600 "
        @onCancel="unsubscribeModal = false"
        @onConfirm="unsubscribeBenefitHandler(apiData.benefit.id)"
        :description="loc.confirm_unsubscribe_question"
    />
  </NewModal>
</template>

<script>
import {HeartIcon, ShoppingCartIcon} from '@heroicons/vue/solid';
import {mapActions, mapGetters, mapState} from 'vuex';
import NewModal from '@/components/ui/NewModal.vue';
import ConfirmDenyModal from '@/components/ui/ConfirmDenyModal.vue';
import axios from '../config/http';

export default {
  name: "Subscriptions",
  data() {
    return {
      loading: false,
      unsubscribeModal: false,
      apiData: null,
      success: '',
      error: '',
    }
  },
  components: {NewModal, ConfirmModal: ConfirmDenyModal, ShoppingCartIcon, HeartIcon},
  computed: {
    ...mapGetters('auth', {
      userFavoriteIds: 'userFavoriteIds',
      userCartIds: 'userCartIds',
    }),
    ...mapState('benefits', ['benefits_type']),
    ...mapState('ui', ['benefit_preview_type']),
    ...mapGetters('auth', ['isAuth', 'user']),
    ...mapGetters('subscriptionStore', ['subscriptionsData']),
  },
  mounted() {
    this.getSubscriptionsHandler();
  },
  methods: {
    ...mapActions('auth', {
      toggleFavorites: 'toggleFavorites',
      toggleCart: 'toggleCart',
    }),
    ...mapActions('benefits', ['fetchBenefits', 'fetchHome', 'load_mode', 'set_benefits_type']),
    ...mapActions('filters', ['select_category']),
    ...mapActions('subscriptionStore', ['unsubscribeBenefit', 'getSubscriptions']),
    async getSubscriptionsHandler() {
      try {
        await this.getSubscriptions();
      } catch (e) {
        console.log(e, 'err');
      }
    },
    openConfirmModal(payload) {
      this.apiData = payload;
      this.unsubscribeModal = true;
    },
    getBaseImg(images) {
      if (!images || !images.length) {
        return null
      }

      function compare( a, b ) {
        if ( a.position < b.position ){
          return -1;
        }
        if ( a.position > b.position ){
          return 1;
        }
        return 0;
      }

      return images.sort( compare )[0].image;
    },
    async unsubscribeBenefitHandler(benefitId) {
      try {
        await this.unsubscribeBenefit(benefitId);
        this.success = this.loc.successfully_unsubscribed;
        this.unsubscribeModal = false;
      } catch (e) {
        this.error = this.loc.something_went_wrong;
        console.log(e, 'err');
      }
    }
  },
  watch: {
    error(val) {
      if (val) {
        setTimeout(() => {
          this.error = null;
        }, 5000);
      }
    },
  },
}
</script>


<style scoped>

</style>